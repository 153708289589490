import PropTypes from "prop-types";
import React from "react";

import Home from "../components/home/Home";
import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function IndexPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "Maps"]}
          location={location}
          metaDescription="Des cartes interactives pour votre business : intégrez des maps et du geocoding dans vos sites et applications."
          title="Home"
        />
        <Page>
          <Home />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

IndexPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default IndexPage;
